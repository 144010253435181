import {RegexConstant} from '../constants';

export class ValidationUtil {

    static emailOrDocumentValidation(value: string): boolean {
        const isValidEmail = RegexConstant.email.test(value);
        const isValidDocument = RegexConstant.document.test(value);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return isValidEmail || isValidDocument;
    }

    static emailValidation(value: string): boolean {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return RegexConstant.email.test(value);
    }

    static telephoneValidation(value: string): boolean {
        if (!value || value === '') return true;

        const isValidTelephone = RegexConstant.telephoneRegex.test(value);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return isValidTelephone;
    }

    static cpfValidation(value: string): boolean {
        let sum = 0;
        let rest = 0;

        const strCPF = String(value).replace(/[^\d]/g, '');

        if (strCPF.length !== 11) {
            return false;
        }

        if ([
            '00000000000',
            '11111111111',
            '22222222222',
            '33333333333',
            '44444444444',
            '55555555555',
            '66666666666',
            '77777777777',
            '88888888888',
            '99999999999',
        ].indexOf(strCPF) !== -1) {
            return false;
        }

        for (let i = 1; i <= 9; i++) {
            sum = sum + parseInt(strCPF.substring(i-1, i), 10) * (11 - i);
        }

        rest = (sum * 10) % 11;

        if ((rest === 10) || (rest === 11)) {
            rest = 0;
        }

        if (rest !== parseInt(strCPF.substring(9, 10), 10)) {
            return false;
        }

        sum = 0;

        for (let i = 1; i <= 10; i++) {
            sum = sum + parseInt(strCPF.substring(i-1, i), 10) * (12 - i);
        }

        rest = (sum * 10) % 11;

        if ((rest === 10) || (rest === 11)) {
            rest = 0;
        }

        return rest === parseInt(strCPF.substring(10, 11), 10);
    }

    static cnpjValidation(cnpj: string): boolean {

        cnpj = cnpj.replace(/[^\d]+/g,'');

        if(cnpj === '') {
            return false;
        }

        if (cnpj.length !== 14)
            return false;

        if (cnpj === '00000000000000' ||
            cnpj === '11111111111111' ||
            cnpj === '22222222222222' ||
            cnpj === '33333333333333' ||
            cnpj === '44444444444444' ||
            cnpj === '55555555555555' ||
            cnpj === '66666666666666' ||
            cnpj === '77777777777777' ||
            cnpj === '88888888888888' ||
            cnpj === '99999999999999') {
            return false;
        }

        let size = cnpj.length - 2
        let numbers = cnpj.substring(0, size);
        const digits = cnpj.substring(size);
        let sum = 0;
        let pos = size - 7;

        for (let i = size; i >= 1; i--) {
            sum += parseInt(numbers.charAt(size - i), 10) * pos--;
            if (pos < 2)
                pos = 9;
        }

        let resultado = sum % 11 < 2 ? 0 : 11 - sum % 11;

        if (resultado.toString() !== digits.charAt(0)) {
            return false;
        }

        size = size + 1;
        numbers = cnpj.substring(0,size);
        sum = 0;
        pos = size - 7;

        for (let i = size; i >= 1; i--) {
            sum += parseInt(numbers.charAt(size - i), 10) * pos--;
            if (pos < 2)
                pos = 9;
        }

        resultado = sum % 11 < 2 ? 0 : 11 - sum % 11;

        return resultado.toString() === digits.charAt(1);
    }

    static nisValidation(value: string): boolean {
        const VALID_LENGTH = 11;

        const baseMultiplier = '3298765432';
        let total = 0;
        let rest = 0;
        let multiplying = 0;
        let multiplier = 0;
        let digit = 99;

        if (!value) {
            return true;
        }

        const nis = value.replace(/[^\d]+/g, '');

        if (nis.length !== VALID_LENGTH ||
            nis === '00000000000' ||
            nis === '11111111111' ||
            nis === '22222222222' ||
            nis === '33333333333' ||
            nis === '44444444444' ||
            nis === '55555555555' ||
            nis === '66666666666' ||
            nis === '77777777777' ||
            nis === '88888888888' ||
            nis === '99999999999') {
            return false;
        } else {
            for (let i = 0; i < 10; i++) {
                multiplying = parseInt(nis.substring( i, i + 1 ), 10);
                multiplier = parseInt(baseMultiplier.substring( i, i + 1 ), 10);
                total += multiplying * multiplier;
            }

            rest = 11 - total % 11;
            rest = rest === 10 || rest === 11 ? 0 : rest;
            digit = parseInt('' + nis.charAt(10), 10);

            return rest === digit;
        }
    }

    static cepValidation(value: string): boolean {
        const VALID_LENGTH = 8;
        return value.length >= VALID_LENGTH;
    }
}

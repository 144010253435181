import Keycloak, {KeycloakInitOptions} from 'keycloak-js';

export class RnpKeycloakConfig {

    private readonly config = {
        url: window.env.KEYCLOAK_URL as string,
        realm: window.env.KEYCLOAK_REALM as string,
        clientId: window.env.KEYCLOAK_CLIENT_ID as string
    };

    private readonly initOptions: KeycloakInitOptions = {
        responseMode: 'fragment',
        flow: 'standard',
        onLoad: 'check-sso'
    };

    private keycloakInstance: Keycloak;

    constructor() {
        this.keycloakInstance = new Keycloak(this.config);
    }

    getInstance(): Keycloak {
        return this.keycloakInstance;
    }

    getInitOptions(): KeycloakInitOptions {
        return this.initOptions;
    }
}

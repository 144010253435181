export class MaskUtil {

    static year(e: any) {
        e.currentTarget.maxLength = 4;

        let value: string = e.currentTarget.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/^(\d{5})/, '');

        e.currentTarget.value = value;
    }

    static cnpj(e) {
        e.currentTarget.maxLength = 14;

        let value: string = e.currentTarget.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5');

        e.currentTarget.value = value;
    }

    static cpf(e) {
        e.currentTarget.maxLength = 14;

        let value: string = e.currentTarget.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d)/, '$1.$2');
        value = value.replace(/(\d{3})(\d{1,2})$/, '$1-$2');

        e.currentTarget.value = value;
    }

    static dateTime(e) {
        e.currentTarget.maxLength = 16;

        let value: string = e.currentTarget.value;
        value = value.replace(/\D/g, '');
        value = value.replace(
            /^(\d{4})(\d{2})(\d{2})(\d{2})(\d{2})/,
            '$3/$2/$1 $4:$5'
        );

        e.currentTarget.value = value;
    }

    static telephone(e) {
        e.currentTarget.maxLength = 14;

        let value: string = e.currentTarget.value;
        value = value.replace(/\D/g, '');
        value = value.replace(/^(\d)/, '($1');
        value = value.replace(/(.{3})(\d)/, '$1)$2');

        if (value.length === 9) {
            value = value.replace(/(.{1})$/, '-$1');
        } else if (value.length === 10) {
            value = value.replace(/(.{2})$/, '-$1');
        } else if (value.length === 11) {
            value = value.replace(/(.{3})$/, '-$1');
        } else if (value.length === 12) {
            value = value.replace(/(.{4})$/, '-$1');
        } else if (value.length > 12) {
            value = value.replace(/(.{4})$/, '-$1');
        }

        e.currentTarget.value = value;
    }

    static unmask(str: string): string {
        return str.replace(/\D/g, '');
    }
}

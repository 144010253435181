export class LocalStorage {

    set(key: string, value: string) {
        if (typeof value === 'object') {
            value = `[object]${JSON.stringify(value)}`;
        }

        localStorage.setItem(key, value);
    }

    get(key: string): string | null {
        let value = localStorage.getItem(key);

        if (value && value.startsWith('[object]')) {
            value = JSON.parse(value.replace('[object]', ''));
        }

        return value;
    }

    remove(key: string) {
        localStorage.removeItem(key);
    }

    clear() {
        localStorage.clear();
    }

    getStorage(): Storage {
        return localStorage;
    }
}

export class CookieStorage {

    getExpireDate(): string {
        return new Date(Date.now() + 10800).toUTCString();
    }

    getSecureCookie(): string {
        return window.env.SECURE_COOKIE ? 'secure;' : '';
    }

    getCookieInfo(): string {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return `domain=${window.env.DOMAIN};path=/;${this.getSecureCookie()}`
    }

    set(key: string, value: string, expires: string) {
        if (typeof value === 'object') {
            value = `[object]${JSON.stringify(value)}`;
        }

        if (!expires) {
            expires = this.getExpireDate();
        }

        document.cookie = `${key}=${value};expires=${expires};${this.getCookieInfo()}`;
    }

    get(key: string): string | null {
        const nameEQ = key + '=';
        const cookie = document.cookie.split(';');

        for(const element of cookie) {
            let c = element;

            while (c.charAt(0) === ' ') {
                c = c.substring(1,c.length);
            }

            if (c.indexOf(nameEQ) === 0) {
                return c.substring(nameEQ.length, c.length);
            }
        }

        return null;
    }

    remove(key: string) {
        document.cookie = `${key};expires=Thu, 01 Jan 1970 00:00:01 GMT;${this.getCookieInfo()}`;
    }

    clear() {
    }

    getStorage() {
        return document.cookie;
    }
}

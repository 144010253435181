export class Base64Util {

    static encodeBase64(plainString: string): string {
        return btoa(unescape(encodeURIComponent(plainString)));
    }

    static decodeBase64(encodedString: string): string {
        return window.atob(encodedString);
    }
}

export class StringUtil {

    static formatString(str: string, values: Array<any>): string {
        return Object.keys(values).reduce(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            (current, key) => current.replace(`{${key}}`, values[key]),
            str
        )
    }

    static convertToHtmlLineBreak(str: string): string {
        if (str) {
            str = str.replace(/\n/g, '<br />');
        }
        return str;
    }

    static removeHtmlLineBreak(str: string): string {
        if (str) {
            str = str.replace(/<br\s*\/?>/gi, '\n');
        }
        return str;
    }

    static getFormatedDate(date: string): string {
        const d = new Date(date + 'T00:00:00');
        const day = `0${d.getDate()}`.slice(-2);
        const month = `0${d.getMonth()+1}`.slice(-2);
        const year = d.getFullYear();
        return  `${day}/${month}/${year}`;
    }

    static isIsoDate(str: string): boolean {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) {
            return false;
        }

        const d = new Date(str);
        return d.toISOString() === str;
    }

    static convertStringToIsoDate(str: string): string {
        const dateParts = str.split('/');
        const date = new Date(+dateParts[2], parseInt(dateParts[1], 10) - 1, + parseInt(dateParts[0], 10));
        return date.toISOString();
    }
}

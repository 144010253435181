export class Config {

    AUTH_URL: string;

    FRONTEND_URL: string;

    GOV_BR_URI: string;

    API_URL: string;

    COGNITO_REGION: string;

    COGNITO_USER_POOL_ID: string;

    COGNITO_CLIENT_ID_NO_SECRET: string;

    RECAPTCHA_KEY_V2: string;

    RECAPTCHA_DISABLED: boolean;

    SWAGGER_HUB_BASE_URL: string;

    SWAGGER_HUB_AUTHORIZATION: string;

    DOMAIN: string;

    SECURE_COOKIE: boolean;

    KEYCLOAK_URL: string;

    KEYCLOAK_REALM: string;

    KEYCLOAK_CLIENT_ID: string;

    constructor (rawData: string) {
        const jsonData = JSON.parse(rawData);

        this.AUTH_URL = jsonData.AUTH_URL;
        this.FRONTEND_URL = jsonData.FRONTEND_URL;
        this.GOV_BR_URI = jsonData.GOV_BR_URI;
        this.API_URL = jsonData.API_URL;
        this.COGNITO_REGION = jsonData.COGNITO_REGION;
        this.COGNITO_USER_POOL_ID = jsonData.COGNITO_USER_POOL_ID;
        this.COGNITO_CLIENT_ID_NO_SECRET = jsonData.COGNITO_CLIENT_ID_NO_SECRET;
        this.RECAPTCHA_KEY_V2 = jsonData.RECAPTCHA_KEY_V2;
        this.RECAPTCHA_DISABLED = jsonData.RECAPTCHA_DISABLED;
        this.SWAGGER_HUB_BASE_URL = jsonData.SWAGGER_HUB_BASE_URL;
        this.SWAGGER_HUB_AUTHORIZATION = jsonData.SWAGGER_HUB_AUTHORIZATION;
        this.DOMAIN = jsonData.DOMAIN;
        this.SECURE_COOKIE = jsonData.SECURE_COOKIE;
        this.KEYCLOAK_URL = jsonData.KEYCLOAK_URL;
        this.KEYCLOAK_REALM = jsonData.KEYCLOAK_REALM;
        this.KEYCLOAK_CLIENT_ID = jsonData.KEYCLOAK_CLIENT_ID;
    }
}

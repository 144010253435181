import * as JsEncryptModule from 'jsencrypt';

export class CryptoUtil {

    public static encryptRsaData(publicKey: string, data: string): string | false {
        const enc = new JsEncryptModule.JSEncrypt({});
        enc.setPublicKey(publicKey);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return enc.encrypt(data);
    }

    public static decryptRsaData(privateKey: string, data: string): string | false {
        const dec = new JsEncryptModule.JSEncrypt({});
        dec.setPrivateKey(privateKey);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return dec.decrypt(data);
    }
}

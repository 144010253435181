import { Guid } from 'guid-typescript';

export class ObjectUtil {

    public static generateUUID():string {
        return Guid.create().toString();
    }

    public static createClass(context: any, className: string, ...args: any[]): any {
        const instance = Object.create(context[className].prototype);
        // eslint-disable-next-line prefer-spread
        instance.constructor.apply(instance, args);
        return instance;
    }
}

import {RnpKeycloakConfig} from '../config';
import Keycloak from 'keycloak-js';
import {BehaviorSubject} from 'rxjs';
import {RnpAuthInterface} from '@app-starter/rnp-core';

export class KeycloakService implements RnpAuthInterface {

    private keycloakInstance: Keycloak;

    private initEvent: BehaviorSubject<boolean>;

    private isUserAuthenticated: BehaviorSubject<boolean>;

    private signOutEvent: BehaviorSubject<boolean>;

    private redirectUri: string;

    // eslint-disable-next-line no-unused-vars
    constructor(http, storage) {
        this.redirectUri = window.env.FRONTEND_URL;

        this.initEvent = new BehaviorSubject<boolean>(false);
        this.isUserAuthenticated = new BehaviorSubject<boolean>(false);
        this.signOutEvent = new BehaviorSubject<boolean>(false);

        const config: RnpKeycloakConfig = new RnpKeycloakConfig();
        this.keycloakInstance = config.getInstance();

        this.keycloakInstance.init(config.getInitOptions()).then(authenticated => {
            console.log('Keycloak init Success (' + (authenticated ? 'Authenticated' : 'Not Authenticated') + ')');
            this.setIsAuthenticated(authenticated);
            this.setInitEvent(true);
        }).catch(error => {
            console.error('Keycloak init error');
            console.error(error);
            this.setInitEvent(false);
        });

        this.keycloakInstance.onTokenExpired = () => {
            console.log('Keycloak token expired');
            this.keycloakInstance.updateToken(30).then(() => {
                console.log('Keycloak token refreshed');
            }).catch((err) => {
                console.log('Keycloak error on refresh token');
                console.error(err);

                console.log('Keycloak signOutEvent');
                this.setSignOutEvent(true);

                this.signOut().then(() => {
                    console.log('Keycloak signout success');
                }).catch((error) => {
                    console.log('Keycloak signout error');
                    console.error(error);
                });
            });
        };

        this.keycloakInstance.onAuthLogout = () => {
            console.log('Keycloak signOutEvent');
            this.setSignOutEvent(true);
        };
    }

    async signIn(redirectUri?: string): Promise<void> {
        const callbackUrl = !redirectUri ? this.redirectUri : redirectUri;
        await this.keycloakInstance.login({ redirectUri: callbackUrl });
    }

    async signOut(redirectUri?: string): Promise<void> {
        const callbackUrl = !redirectUri ? this.redirectUri : redirectUri;
        await this.keycloakInstance.logout({ redirectUri: callbackUrl });
        this.setSignOutEvent(true);
    }

    govBrSignIn(): void {
    }

    // eslint-disable-next-line no-unused-vars
    accessTokenSignIn(jwt: any, token: any, username: string): void {
    }

    govBrSignOut(): void {
    }

    clearAuthStorage(): void {
    }

    restoreSession(): any {
        return null
    }

    // eslint-disable-next-line no-unused-vars
    registerListener(event, listener): void {
    }

    // eslint-disable-next-line no-unused-vars
    getFullToken(code: string): string | null {
        return null;
    }

    getBearerToken(): string | null {
        if (this.keycloakInstance.isTokenExpired()) {
            return null;
        }

        return `Bearer ${this.keycloakInstance.token}`;
    }

    isAuthenticated(): BehaviorSubject<boolean> {
        return this.isUserAuthenticated;
    }

    setIsAuthenticated(isAuthenticated: boolean): void {
        this.isUserAuthenticated.next(isAuthenticated);
    }

    setInitEvent(active: boolean): void {
        this.initEvent.next(active);
    }

    onInitEvent(): BehaviorSubject<boolean> {
        return this.initEvent;
    }

    setSignOutEvent(active: boolean): void {
        this.signOutEvent.next(active);
    }

    onSignOutEvent(): BehaviorSubject<boolean> {
        return this.signOutEvent;
    }

    async register(redirectUri?: string): Promise<void> {
        const callbackUrl = !redirectUri ? this.redirectUri : redirectUri;
        await this.keycloakInstance.register({ redirectUri: callbackUrl });
    }
}

export class ArrayUtil {

    static uniqByKeepFirst(arr: Array<any>, key: any): Array<any> {
        const seen = new Set();

        return arr.filter(item => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            const k = key(item);
            return seen.has(k) ? false : seen.add(k);
        });
    }
}

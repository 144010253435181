import {DocumentTypeEnum} from '../enums/DocumentType.enum';

export class DocumentUtil {

    static getFormatedDocument(documentTypeId: DocumentTypeEnum, value: string): string {
        if (documentTypeId === DocumentTypeEnum.CPF) {
            value = value.replace(/[^\d]/g, '');
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
        } else {
            return value;
        }
    }

    static hideDocumentNumber(documentTypeId: DocumentTypeEnum, value: string): string {
        if (documentTypeId === DocumentTypeEnum.CPF) {
            value = value.replace(/[^\d]/g, '');
            return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '***.$2.***-**');
        } else if (documentTypeId === DocumentTypeEnum.PASSPORT) {
            const length = value.length - 1;
            let newValue = value[0];

            for (let i = 0; i < length; i++) {
                newValue += '*';
            }

            newValue += value[length];

            return newValue;
        } else {
            return value;
        }
    }
}

import axios, {AxiosRequestConfig, AxiosInstance} from 'axios';
import {RnpRequestError} from './RnpRequestError';
import {FileUtil} from '../util';

export class RnpHttpClient {

    instance: AxiosInstance;

    cancelTokenSources: Array<any>;

    constructor(config: AxiosRequestConfig) {
        this.instance = axios.create(config);
        this.cancelTokenSources = [];
    }

    mapRequestOptions(options: AxiosRequestConfig): AxiosRequestConfig {
        return {
            withCredentials: true,
            xsrfCookieName: 'XSRF-TOKEN',
            xsrfHeaderName: 'X-XSRF-TOKEN',
            ...options
        };
    }

    // eslint-disable-next-line max-len
    useInterceptor(interceptor: ((value: AxiosRequestConfig<any>) => AxiosRequestConfig<any> | Promise<AxiosRequestConfig<any>>) | undefined) {
        return this.instance.interceptors.request.use(interceptor, error => {
            console.error('[Axios] error', error);
            return Promise.reject(error);
        });
    }

    addToBag() {
        const axiosCancelTokenSource = axios.CancelToken.source();

        this.cancelTokenSources.push(axiosCancelTokenSource);

        return axiosCancelTokenSource.token;
    }

    disposeRequests(cancelMessage = 'Request canceled by user') {
        while (this.cancelTokenSources.length > 0) {
            const cancelSource = this.cancelTokenSources.shift();
            cancelSource.cancel(cancelMessage);
        }
    }

    async handleErrors(error: any) {
        return Promise.reject(new RnpRequestError(error.message, error.response));
    }

    handleRequest(promise: Promise<any>): Promise<any> {
        return promise
            .then(res => {
                const { data } = res;
                if (data.error || data.error_message) {
                    const error = data.error || data.error_message;
                    return Promise.reject(new Error(error));
                }

                return Promise.resolve(data);
            })
            .catch(error => {
                return this.handleErrors(error);
            });
    }

    handleFileRequest(promise: Promise<any>): Promise<any> {
        return promise
            .then(res => {
                const { data } = res;

                if (data.error || data.error_message) {
                    const error = data.error || data.error_message;
                    return Promise.reject(new Error(error));
                }

                const fileName = FileUtil.getFileNameFromContentDisposition(res);

                return Promise.resolve({ blob: data, fileName });
            })
            .catch(error => {
                return this.handleErrors(error);
            });
    }

    get(url: string, options: AxiosRequestConfig = {}): Promise<any> {
        return this.handleRequest(
            this.instance.get(
                url,
                this.mapRequestOptions({
                    ...{
                        data: {},
                        ...options,
                        headers: {
                            accept: 'application/json',
                            ...options.headers
                        }
                    },
                    cancelToken: this.addToBag()
                })
            )
        );
    }

    getFile(url: string, options: AxiosRequestConfig): Promise<any> {
        return this.handleFileRequest(
            this.instance.get(
                url,
                this.mapRequestOptions({
                    responseType: 'blob',
                    cancelToken: this.addToBag(),
                    ...options
                })
            )
        );
    }

    post(url: string, data, options: AxiosRequestConfig): Promise<any> {
        return this.handleRequest(
            this.instance.post(
                url,
                data,
                this.mapRequestOptions({
                    ...options,
                    cancelToken: this.addToBag()
                })
            )
        );
    }

    put(url: string, data, options: AxiosRequestConfig) {
        return this.handleRequest(
            this.instance.put(
                url,
                data,
                this.mapRequestOptions({
                    ...options,
                    cancelToken: this.addToBag()
                })
            )
        );
    }

    patch(url: string, data, options: AxiosRequestConfig): Promise<any> {
        return this.handleRequest(
            this.instance.patch(
                url,
                data,
                this.mapRequestOptions({
                    ...options,
                    cancelToken: this.addToBag()
                })
            )
        );
    }

    delete(url: string, options: AxiosRequestConfig): Promise<any> {
        return this.handleRequest(
            this.instance.delete(url, {
                ...options,
                cancelToken: this.addToBag()
            })
        );
    }
}

export class RnpRequestError extends Error {

    name: string;

    response: any;

    constructor(message: string, response: any) {
        super(message);

        this.name = 'RequestError';
        this.response = response;
    }
}

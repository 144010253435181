import * as SHA256 from 'crypto-js/sha256';

export class FileUtil {

    static async generateFileHash(file: Blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsText(file, 'UTF-8');

            reader.onload = event => {
                if (event && event.target) {
                    const fileContent = event.target.result;
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
                    const fileHash = SHA256(fileContent).toString().toUpperCase();

                    resolve(fileHash);
                }

                resolve(null);
            };

            reader.onerror = event => {
                if (event && event.target) {
                    reject(new Error(event.target.result as string));
                }

                reject(new Error());
            };
        });
    }

    static getFileNameFromContentDisposition(response: any): string | null {
        const contentDisposition = new URLSearchParams(
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            response.headers['content-disposition']
        );

        return contentDisposition.get('attachment; filename');
    }
}

export class ClipboardUtil {

    copyToClipboard(value: string, copyTimeoutCallback: any) {
        const textField = document.createElement('textarea');

        textField.innerText = value;

        document.body.appendChild(textField);
        textField.select();
        document.execCommand('copy');
        textField.remove();

        // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-return
        setTimeout(() => copyTimeoutCallback(), 2000);
    }
}

export const RegexConstant = {

    email: /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/,

    document: /(\d{11})+$/,

    telephoneRegex: /^\d{2}\d{8,9}$/,

    cpf: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/
}
